import type { AdditionalCost, PriceCleaningType } from '@/domain';
import getConfig from 'next/config';
import useFetch from 'shared/hooks/useFetch';
// import useSWRMutation from 'swr/mutation';
// import { createOrUpdatePriceInfo } from '@/api/api';

const {
    publicRuntimeConfig: { apiExternalUrl },
    serverRuntimeConfig: { apiInternalUrl },
} = getConfig();

// export const fetchPriceDataSSR = async (adId: string): Promise<PriceData> => {
//     const path = `/price/info/${adId}`;

//     return await fetch(`${apiInternalUrl}/travel-api/fhh${path}`)
//         .then((res) => res.json())
//         .catch(() => defaultValues(adId));
// };
// export const priceDataUrl = (adId: string) => `${apiExternalUrl}/price/info/${adId}`;
// const apiUrl = `${apiExternalUrl}/price/additional/${adId}`;

export const fetchAdditionalCostSSR = async (adId: string): Promise<AdditionalCost> => {
    const path = `/price/additional/${adId}`;

    return await fetch(`${apiInternalUrl}/travel-api/fhh${path}`)
        .then((res) => res.json())
        .catch(() => defaultValues(adId));
};

export const getAdditionalCostUrl = (adId: string) => `${apiExternalUrl}/price/additional/${adId}`;

const defaultValues = (adId?: string): AdditionalCost => ({
    adId: adId ?? null,
    priceDepositRequired: null,
    priceDepositAmount: null,
    priceDepositType: null,
    priceCleaningType: 'INCLUDED',
    priceCleaning: null,
    priceLinens: null,
    priceExtraPerGuest: null,
    priceExtraPerGuestWhenCountAbove: null,
});

export const useAdditionalCost = (adId: string, isImmutable = false, isEnabled = true) =>
    useFetch<AdditionalCost>({
        isEnabled,
        url: getAdditionalCostUrl(adId),
        isImmutable,
        statusCodeHandlers: {
            404: () => defaultValues(adId),
        },
    });

// const post = async (_url, { arg }: { arg: AdditionalCost }) => createOrUpdatePriceInfo(arg);

// export const usePriceDataMutation = (adId: string) => useSWRMutation(priceDataUrl(adId), post, { throwOnError: false });

export const isCleaningType = (cleanType: string): cleanType is PriceCleaningType => {
    const cleaningType: PriceCleaningType[] = ['INCLUDED', 'FEE', 'GUEST_CLEANS'];
    return cleaningType.includes(cleanType as PriceCleaningType);
};
