import { useEffect } from 'react';
import cn from 'classnames';
import { Alert } from '@fabric-ds/react';

import { formatMoney } from 'shared/util/formatting';
import { SpinnerIcon } from 'shared/icons/SpinnerIcon';

import { usePriceCalendar } from '@/hooks/usePriceCalendar';
import { PriceDetail } from './PriceDetail';
import type { ObjectLocation } from '@/domain';
import { WELCOME_TO_NEW_FHH_ON_HELPCENTER } from '@/constants';

export interface PriceOverviewProps {
    adId: number;
    location: ObjectLocation;
    startDate: Date;
    endDate: Date;
    numberOfGuests: number;
    setPrice?: (priceTotal: string) => void;
    className?: string;
}

export const PriceOverview = ({ adId, location, startDate, endDate, numberOfGuests, setPrice, className }: PriceOverviewProps) => {
    const norwegianAddress = location?.countryCode === 'NO';
    const { priceCalendar, error, isLoading } = usePriceCalendar(adId.toString(), startDate, endDate, numberOfGuests);
    useEffect(() => {
        if (priceCalendar && setPrice && !norwegianAddress) {
            setPrice(formatMoney({ value: priceCalendar.priceNotIncludingServiceFee, currency: priceCalendar.currency }, 0));
        }
    }, [priceCalendar]);

    if (isLoading) return <SpinnerIcon />;
    if (error)
        return (
            <Alert type="negative" show>
                Beklager, noe gikk galt ved henting av pris.
            </Alert>
        );
    if (!priceCalendar)
        return (
            <Alert type="info" show>
                Ta kontakt med utleier for pris.
            </Alert>
        );

    const titleWithNumberOfNights = `Pris for ${priceCalendar.pricesOfDateRange?.length} ${
        (priceCalendar.pricesOfDateRange?.length ?? 0) > 1 ? 'netter' : 'natt'
    }`;

    const renderCleaning = () => {
        switch (priceCalendar.cleaningType) {
            case 'FEE':
                return <PriceDetail title="Rengjøring" money={{ value: priceCalendar.cleaningFee, currency: priceCalendar.currency }} />;
            case 'INCLUDED':
                return (
                    <p className="grid grid-cols-2 gap-16 my-8">
                        <span className="text-left">Rengjøring</span>
                        <span className="text-right">Inkludert</span>
                    </p>
                );
            case 'GUEST_CLEANS':
                return (
                    <p className="grid grid-cols-2 gap-16 my-8">
                        <span className="text-left">Rengjøring</span>
                        <span className="text-right">Leietaker vasker</span>
                    </p>
                );
        }
    };

    const totalPrice = norwegianAddress ? priceCalendar.priceIncludingServiceFee : priceCalendar.priceNotIncludingServiceFee;

    return (
        <div className={cn(className, 'text-14')}>
            <h3 className="text-14">Pris</h3>
            <PriceDetail
                title={titleWithNumberOfNights}
                money={{ value: priceCalendar.priceOfStayWithExtraCost, currency: priceCalendar.currency }}
            />
            {priceCalendar.discount > 0 && (
                <PriceDetail
                    title="Rabatt for lengre opphold"
                    money={{ value: -1 * priceCalendar.discount, currency: priceCalendar.currency }}
                />
            )}
            {renderCleaning()}
            {norwegianAddress && (
                <PriceDetail
                    title="FINN.no tjenestegebyr"
                    money={{ value: priceCalendar.serviceFee, currency: priceCalendar.currency }}
                    extraPriceInformation={serviceFeeInfo}
                />
            )}
            <PriceDetail title="Totalpris" money={{ value: totalPrice, currency: priceCalendar.currency }} />
            {!norwegianAddress && priceCalendar.deposit && (
                <div className="my-8 text-left text-gray-500 text-12">Depositum kommer i tillegg</div>
            )}
        </div>
    );
};

const serviceFeeInfo = {
    description: 'Tjenestegebyret gjør at vi kan fortsette å vedlikeholde og utvikle tjenestene våre. ',
    link: {
        text: 'Les mer om gebyret.',
        url: `${WELCOME_TO_NEW_FHH_ON_HELPCENTER}#FINNs-tjenestegebyr`,
    },
};
